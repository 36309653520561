<template>
  <div>
    <ComponentModalActivityLog
        v-on:handleClose="
          dialogSeeActivityLog = false;"
        :modal="dialogSeeActivityLog"
        type=""
        process="usuarios"
        :consecutive="consecutive"
        v-if="dialogSeeActivityLog"
    ></ComponentModalActivityLog>

    <modal
      ref="modalDialog"
      :persistent="false"
      :maxWidth="600"
      title="Gestionar Usuario"
    >
      <template #default>
        <div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="username"
                  label="Usuario*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="firstname"
                  label="Nombre(s)*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="lastname"
                  label="Apellido(s)*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="email"
                  label="Email*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="cargo"
                  label="Cargo*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Password*"
                  @click:append="showPassword = !show1"
                  required
                  :type="showPassword ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="phone"
                  label="Telefono"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="allRoles"
                  label="Role*"
                  v-model="role"
                  item-text="name"
                  item-value="id"
                  single
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6">
                <v-file-input label="Imagen de la Firma" @change="loadImage">
                </v-file-input>
              </v-col>
              <v-col cols="12" sm="6">
                <v-avatar
                  class="profile ma-4"
                  color="grey"
                  style="min-width: 120px; height: 100px"
                  tile
                >
                  <v-img :src="firma"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
      <template #actions>
        <small class="mx-4">* Indicador de campo requerido</small>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('crearUsuarios')"
         color="success darken-1 my-3 mx-3" @click="crearUsuario"
          >Guardar</v-btn
        >
      </template>
    </modal>
    <v-card class="mx-auto">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        :single-select="singleSelect"
        v-model="selected"
        :headers="headers"
        :items="allUsers"
        :search="search"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <export-excel :data="allUsers" style="margin-bottom: 10px">
              <img
                :src="require('@/assets/media/rpg/excel.png')"
                height="42"
                width="42"
                class="mt-4"
              />
            </export-excel>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
              v-on="on"
            ></v-text-field>
            <v-btn
              color="gray darken-4 ml-4 white--text"
              small
              dark
              class="mb-2"
              @click="$router.push({ path: '/users-activity' })"
            >
              <v-icon small class="mr-2">assignment</v-icon>Actividades
            </v-btn>
            <v-btn
              color="indigo darken-4 white--text"
              dark
              class="mb-2 ml-3"
              @click.stop="openDialog"
            >
              <v-icon left small>fa-plus</v-icon>Nuevo
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.is_active="{ item }">
          <template v-if="item.is_active == 1">
            <v-chip dark color="green">Activo</v-chip>
          </template>
          <template v-else>
            <v-chip dark color="red">Inactivo</v-chip>
          </template>
        </template>
        <template v-slot:item.action="{ item }">
          <v-menu left bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-if="$can('editarUsuarios')" @click="Edit(item)">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Editar
                </v-list-item-title>
              </v-list-item>
                  <v-list-item @click="showPermissions(item)" v-if="$can('editarRoles')">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Permisos especiales
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showEmbarcaciones(item)" v-if="item.roles.length > 0 && item.roles[0].name == 'Consulta De Buques'">
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>Asignar embarcaciones
                </v-list-item-title>
              </v-list-item>
              <template v-if="item.is_active == 1">
                <v-list-item v-if="$can('deshabilitarUsuarios')" @click="Desactive(item)">
                  <v-list-item-title >
                    <v-icon class="mr-2" small></v-icon>Desactivar
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item v-if="$can('deshabilitarUsuarios')" @click="Active(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small></v-icon>Activar
                  </v-list-item-title>
                </v-list-item>
              </template>
               <v-list-item  @click="seeActivityLog(item.id)" >
                <v-list-item-title>
                  <v-icon class="mr-2" small></v-icon>
                 Historial de actividades
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <modal
      :maxWidth="900"
      :persistent="true"
      title="Asignar permisos"
      ref="modalDialogPermissions"
    >
      <template #default>
        <div class="py-5">
          <role-permission
            :ownerName="user.username"
            ref="rolePermission"
            @onDrop="setPermissions"
            :user="user"
          ></role-permission>
        </div>
      </template>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="assignPermissions()" color="success" elevation="2">Guardar</v-btn>
      </template>
    </modal>
    <modal
      :maxWidth="900"
      :persistent="true"
      title="Asignar embarcaciones"
      ref="modalDialogEmbarcaciones"
    >
      <template #default>
        <div class="py-5">
          <AssignBoat
            :ownerName="user.username"
            ref="rolePermission"
            @onDrop="setBoats"
            :user="user"
          ></AssignBoat>
        </div>
      </template>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="assignBoats()" color="success" elevation="2">Guardar</v-btn>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { createUser, UserDesactive, UserActive, getHeader, firmaUrl } from "./../config.js";
import swal from "sweetalert";
import Modal from "../components/Modal.vue";
import RolePermision from "../components/role-permission"
import AssignBoat from "../components/AssignBoat"
import permissionService from '../services/permissionService.js';
import ComponentModalActivityLog from "../components/ComponentModalActivityLog";
import {
  syncBoatsByUser,
} from "../services/exportboats";

export default {
  data: () => ({
    dialogNewUser: false,
    dialog: false,
    singleSelect: false,
    selected: [],
    username: "",
    firstname: "",
    showPassword: false,
    dialogSeeActivityLog: false,
    lastname: "",
    phone: "",
    email: "",
    cargo: "",
    password: "",
    role: "",
    search: "",
    headers: [
      {
        text: "Usuario",
        align: "left",
        filterable: true,
        value: "username",
        width: "20%",
      },
      { text: "Nombre(s)", value: "firstname", width: "20%" },
      { text: "Apellidos(s)", value: "lastname", width: "20%" },
      { text: "Telefono", value: "phone", width: "5%" },
      { text: "Email", value: "email", width: "20%" },
      { text: "Cargo", value: "cargo", width: "20%" },
      { text: "Rol", value: "roles[0].name", width: "20%" },
      { text: "Fecha Creación", value: "created_at", width: "25%" },
      { text: "Estado", value: "is_active", width: "25%" },
      { text: "Acción", value: "action", width: "25%" },
    ],
    firma: "",
    edit: false,
    userId: "",
    cambio: false,
    user: {},
    currentPermissions: [],
    currentBoats: [],
    consecutive: ''
  }),
  components: { Modal, "role-permission": RolePermision, ComponentModalActivityLog, AssignBoat },
  computed: {
    ...mapGetters(["allUsers", "allRoles"])
  },
  created() {
    this.$store.dispatch("getUsers");
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    seeActivityLog: async function (consecutive) {
      this.consecutive = consecutive;
      this.dialogSeeActivityLog = true;
    },
    ...mapActions(["getRoles", "getPermissions", "getPermissionsByRole", "getPermissionsByUser"]),
    loadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.firma = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    Edit(item) {
      (this.userId = item.id), (this.edit = true);
      this.username = item.username;
      this.firstname = item.firstname;
      this.lastname = item.lastname;
      this.phone = item.phone;
      this.email = item.email;
      this.cargo = item.cargo;

      this.firma_in = item.firma;
      this.role = item.roles[0];
      this.firma = item.firma.length > 0 ? firmaUrl + item.firma : "";
      this.openDialog();
    },
    Desactive(item) {
      swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",

          Si: true,
        },
      }).then((value) => {
        switch (value) {
          case "Si":
            this.axios
              .post(UserDesactive, { id: item.id }, { headers: getHeader() })
              .then(() => {
                this.$store.dispatch("getUsers");
              });
            break;
          default:
            return false;
        }
      });
    },
    Active(item) {
      swal("Seguro desea continuar", {
        buttons: {
          cancel: "Cancelar",

          Si: true,
        },
      }).then((value) => {
        switch (value) {
          case "Si":
            this.axios
              .post(UserActive, { id: item.id }, { headers: getHeader() })
              .then(() => {
                this.$store.dispatch("getUsers");
              });
            break;
          default:
            return false;
        }
      });
    },
    crearUsuario() {
      const postData = {
        username: this.username,
        firstname: this.firstname,
        lastname: this.lastname,
        phone: this.phone,
        email: this.email,
        cargo: this.cargo,
        firma: this.firma,
        firma_in: this.firma_in,
        password: this.password,
        role: this.role,
        edit: this.edit,
        id: this.userId,
      };
      this.axios.post(createUser, postData, { headers: getHeader() }).then(
        (response) => {
          if (response.status === 200) {
            this.dialogNewUser = false;
            (this.userId = ""), (this.edit = false);
            this.username = "";
            this.firstname = "";
            this.lastname = "";
            this.phone = "";
            this.email = "";
            this.cargo = "";
            this.role = "";

            this.firma = "";

            this.$store.dispatch("getUsers");
            this.closeDialog();
          }
        },
        () => {
          //
          this.showStateAlertTechnical = true;
          this.messageStateTechnicalIn =
            "La inspección técnica con el consecutivo ya existe";
        }
      );
    },
    setPermissions(permissions){
      this.currentPermissions = permissions.map(x => x.name);
    },
    setBoats(boats)
    {
      this.currentBoats = boats.map(x => x.id);
    },
    openDialog() {
      this.$refs.modalDialog.openModal();
    },
    closeDialog() {
      this.$refs.modalDialog.closeModal();
    },
    async showPermissions(item){
      this.user = item;
      if (item.roles?.[0]?.id)  {
       await this.getPermissionsByRole(item.roles[0].id);
      }
       await this.getPermissionsByUser(item);
      this.$refs.modalDialogPermissions.openModal();
      this.$nextTick(function () {
         this.$refs.rolePermission.setPayload();
      });
    },
    async showEmbarcaciones(item){
      this.user = item;
      // if (item.roles?.[0]?.id)  {
      //  await this.getPermissionsByRole(item.roles[0].id);
      // }
      //await this.getPermissionsByUser(item);
      this.$refs.modalDialogEmbarcaciones.openModal();
    },
    async assignPermissions(){
      try {
        this.$showLoading();
        await permissionService.syncPermissionByUser(this.user.id, {permissions: this.currentPermissions});
        this.$hideLoading();
        this.userId = '';
        this.$refs.modalDialogPermissions.closeModal();
        this.$toasted.success(`Permisos gestionados con exito`, {
          position: "top-right",
          className: "toast-rgp",
          duration: 5000,
        });
      } catch (error) {
        this.$hideLoading();
        this.userId = '';
         this.$toasted.error(`Ups, ocurrió un error`, {
          position: "top-right",
          className: "toast-rgp",
          duration: 5000,
        });
      }

    },
    async assignBoats()
    {
      try {
        this.$showLoading();
        await syncBoatsByUser(this.user.id, {boats: this.currentBoats});
        this.$hideLoading();
        this.userId = '';
        this.$refs.modalDialogEmbarcaciones.closeModal();
        this.$toasted.success(`Embarcaciones gestionados con exito`, {
          position: "top-right",
          className: "toast-rgp",
          duration: 5000,
        });
      } catch (error) {
        this.$hideLoading();
        this.userId = '';
         this.$toasted.error(`Ups, ocurrió un error`, {
          position: "top-right",
          className: "toast-rgp",
          duration: 5000,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
