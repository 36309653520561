<template>
  <div>
    <v-subheader color="primary"
      >Mueve de izquierda a derecha para asignar embarcaciones a {{ ownerName }},
      tambien puedes mover de derecha a izquierda para revocarlos</v-subheader
    >
      <v-select
        v-model="select_type"
        prepend-inner-icon="map"
        :items="items_select_type"
        item-text="nombre"
        item-value="type"
        hint="Tipo Embarcación"
        @input="getBoatsType"
        persistent-hint
        return-object
        single-line
      ></v-select>
    <div class="d-flex justify-center">
      <v-icon>fas fa-arrows-alt-h</v-icon>
    </div>
    <v-container class="d-flex flex-direction-row justify-center">
      <div class="mx-2 border-all border-danger container-permit" >
        <div>
          <div class="card-column-header mb-3 ml-3">
            Stock de embarcaciones
            <v-btn
              elevation="3"
              outlined
              rounded
              text
              x-small
              color="success"
              @click="assignAll"
              >Asignar todos</v-btn
            >
            <v-text-field
              v-model="buscarField"
              v-on:keyup="buscarFieldEmbarcacion"
              prepend-inner-icon="search"
              placeholder="Buscar Embarcación"
              persistent-hint
              required
            ></v-text-field>
          </div>
          <Container
            group-name="col"
            @drop="(e) => onCardDrop(0, e)"
            :get-child-payload="getCardPayload(0)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
          >
            <Draggable
              v-for="boat in managementPermissions.children[0]"
              :key="boat.id"
            >
              <div class="item-permission mb-3">
                <h3>{{ boat.registration_number +' - '+boat.boat_name }}</h3>
              </div>
            </Draggable>
            <infinite-loading v-if="managementPermissions.children[0].length" @distance="1" @infinite="handleLoadMore"></infinite-loading>
            <v-alert
              style="width: 300px"
              v-if="!managementPermissions.children[0].length"
              shaped
              dark
              color="info"
            >
              No existen mas embarcaciones
            </v-alert>
          </Container>
        </div>
      </div>
      <div class="mx-2 border-all container-permit">
        <div>
          <div class="card-column-header mb-3" >
            Embarcaciones para {{ ownerName }}
          </div>
          <Container
            group-name="col"
            @drop="(e) => onCardDrop(1, e)"
            :get-child-payload="getCardPayload(1)"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
          >
            <Draggable
              v-for="target in managementPermissions.children[1]"
              :key="target.id"
            >
              <div class="item-permission-current mb-3">
                <h3>{{ target.boat_name }}</h3>
              </div>
            </Draggable>
            <v-alert
              style="width: 300px"
              v-if="!managementPermissions.children[1].length"
              shaped
              dark
              color="info"
            >
              no hay embarcaciones para {{ ownerName }}
            </v-alert>
          </Container>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { Container, Draggable } from "vue-dndrop";
import { applyDrag, scene } from "../utils/helpers";
import { mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import swal from "sweetalert";
import {getBoatsUser,
  getBoatsRelaUser
} from "../services/exportboats";

export default {
  props: ["ownerName", "user"],
  name: "AssignBoat",

  components: { Container, Draggable, InfiniteLoading },
  computed: {
    ...mapGetters(["allPermissions", "allPermissionsByRole", "allPermissionsByUser"]),
  },
  data() {
    return {
      Products: [],
      select_type: '',
      items_select_type: [
        {'type': 'IND', 'nombre': 'industriales'},
        {'type': 'ART', 'nombre': 'artesanales'},
      ],
      page: 1,
      buscarField: '',
      scene,
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      managementPermissions: { children: [[], []] },
      permissionsByRoleWhenIsUser: [],
      stock: [],
      boats: []
    };
  },

  methods: {
    async buscarFieldEmbarcacion()
    {
      if(this.select_type == '')
      {
        this.buscarField = '';
        swal({
          title: "Ups!",
          text: "Selecciones un tipo de embarcación",
          icon: "error",
        });return;
      }

      this.page = 1;
      await this.getBoatsFunction();
    },
    async handleLoadMore($state) {
      this.page++;
      getBoatsUser(this.user.id, this.select_type.type, this.page).then(response => {
          let temp = {
            ...this.managementPermissions
          };
          if (response.data.boatsNotUser.data.length > 0) {
            response.data.boatsNotUser.data.forEach(item => temp.children[0].push(item));
            this.managementPermissions = temp;
            $state.loaded();
          } else {
            $state.complete();
          }
        });
    },
    async getBoatsType()
    {
      this.buscarField = '';
      this.page = 1;
      await this.getBoatsFunction();
    },
    async getBoatsFunction()
    {
      let temp = {
        ...this.managementPermissions
      };

      await getBoatsUser(this.user.id, this.select_type.type, this.page, this.buscarField).then(response => 
      {
          temp.children[0] = response.data.boatsNotUser.data;  

          this.managementPermissions = temp;
      });
      
      return temp;
    },
    onCardDrop(columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, this.managementPermissions);
        const column = scene.children[columnId];
        const newColumn = applyDrag(column, dropResult);
        scene.children.splice(columnId, 1, newColumn);
        this.managementPermissions = scene;
        this.$emit("onDrop", this.managementPermissions.children[1]);
      }
    },
    assignAll() {
      this.managementPermissions.children[0].map(
        (x) => this.managementPermissions.children[1].push(x));
      this.managementPermissions.children[0] = [];
      this.$emit("onDrop", this.managementPermissions.children[1]);
      this.$set(this.managementPermissions.children, "0", []);
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.managementPermissions.children[columnId][index];
      };
    }
  },
  created(){
    getBoatsRelaUser(this.user.id).then((response) => {
      let temp = {
          ...this.managementPermissions
      };
      temp.children[1] = response.data.boatsUser;
      this.managementPermissions = temp;
    })
  }
};
</script>
<style scoped>
.border-all {
  border: 1px solid;
  border-style: dotted;
  border-color: #009688;
  padding: 10px;
}
.border-danger {
  border-color: #8a8888ee;
}
.item-permission-current {
  background-color: #009688;
  color: #ffff;
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;
}
.item-permission {
  background-color: #eeee;
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;
}
.item-disable {
  background-color: rgba(192, 192, 192, 0.933);
  padding: 4px;
  line-height: 1.2;
  border-radius: 6px;
  cursor: no-drop;
  color: #838383;
}
.container-permit {
  max-height: 300px;
  overflow: auto;
}
</style>
